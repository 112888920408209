import offices from './offices';
import calendar from './calendar';
import accounts from './accounts';
import treatments from './treatments';
import products from './products';
import notifications from './notifications';
import protocols from './protocols';
import shop from './shop';
import articles from './articles';
import social from './social';
import settings from './settings';
import seo from './seo';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [calendar, treatments, accounts, protocols, offices, products, shop, social, articles, seo, notifications, settings]
};

export default menuItems;
