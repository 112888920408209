import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { UIPageHeader } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import { IconCheck } from '@tabler/icons-react';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { UIFormControlBox } from '../../utilities/Form';
import { toast } from 'react-toastify';

const ModalAddTreatment = ({ modalOpen, handleModalClose }) => {
  const [treatmentsList, seTreatmentsList] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const { onListTreatmentsSeoAdmin, onAddTreatmentSeoDataAdmin } = useTreatments();

  const listTreatments = () => {
    onListTreatmentsSeoAdmin().then(data => {
      seTreatmentsList(data);
    });
  };

  useEffect(() => {
    if (!modalOpen) return;
    listTreatments();
  }, [modalOpen]);

  const handleModalCloseInternal = () => {
    seTreatmentsList([]);
    setSelectedTreatment(null);
    handleModalClose();
  };

  const handleSubmit = async () => {
    onAddTreatmentSeoDataAdmin({
      treatment_id: selectedTreatment
    }).then((res) => {
      if (res?.responseStatus) {
        handleModalCloseInternal();
      }
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: res?.responseStatus ?  '#00e676' : '#FF646C',
          color: '#ffffff'
        }
      });
    })
  };


  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiModalInternal
        onClose={handleModalCloseInternal}
        isLoading={treatmentsList === null}
        containerSx={{
          width: '80%',
          maxWidth: '800px'
        }}
      >
        <UIModalHeader sx={{ border: 'none' }}>
          <UIPageHeader
            title={'Aggiungi nuovo trattamento'}
            sx={{
              textAlign: 'center'
            }}
          />
        </UIModalHeader>
        <UIModalBody
          sx={{
            bgcolor: 'transparent'
          }}
        >

          <UIFormControlBox label={'Trattamento'}>
            <Select
              variant={'filled'}
              labelId="treatment-label"
              id="treatment-select"
              onChange={(event) => {
                setSelectedTreatment(event?.target?.value);
              }}
              input={<OutlinedInput id="categories-input" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: '100%'
                  }
                }
              }}
            >
              {treatmentsList?.map((treatment, index) => (
                <MenuItem key={index} value={treatment?.id}>
                  {treatment?.name}
                </MenuItem>
              ))}
            </Select>

          </UIFormControlBox>


        </UIModalBody>
        <UIModalButtons>
          <UIButton type={'submit'} onClick={() => handleSubmit()}
                    icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
        </UIModalButtons>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalAddTreatment;
