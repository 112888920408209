import React, { useEffect, useState } from 'react';
import { UIPageHeader } from '../../utilities/Headers';
import { Box, Image } from '@chakra-ui/react';
import { useSeo } from '../../../hooks/Seo/useSeo';
import { UIInput, UIInputLabel } from '../../utilities/Input';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { UIFormControlBox } from '../../utilities/Form';
import { CircularProgress, Typography } from '@mui/material';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { UIButton } from '../../utilities/Button';
import { toast } from 'react-toastify';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import UIHtmlEditor from '../../utilities/Editor';
import UITable from '../../utilities/Table';
import ModalEditTreatment from './modalEditTreatment';
import ModalAddTreatment from './modalAddTreatment';

const SeoTreatmentsPage = () => {
  const [pageData, setPageData] = useState(null);
  const { onGetPageTreatments, onUpdateSeoTreatmentsPage } = useSeo();
  const [pageMetadata, setPageMetadata] = useState([]);
  const [contentText, setContentText] = useState('');

  const getPage = () => {
    onGetPageTreatments().then(data => {
      setContentText(data?.description);
      setPageData(data);
      setPageMetadata(data?.metadata.map((m) => ({ name: m.name, content: m.content })));
    });
  };

  useEffect(() => {
    getPage();
  }, []);

  const handleMetadataUpdate = (metadata, index, field) => {
    const newMetadata = [...pageMetadata];
    newMetadata[index][field] = metadata;
    setPageMetadata(newMetadata);
  };

  const handleMetadataAdd = () => {
    const newMetadata = [...pageMetadata];
    newMetadata.push({ name: '', content: '' });
    setPageMetadata(newMetadata);
  };

  const handleMetadataRemove = (index) => {
    const newMetadata = [...pageMetadata];
    newMetadata.splice(index, 1);
    setPageMetadata(newMetadata);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>

      <UIPageHeader
        title={'Seo Trattamenti'}
        subtitle={
          `Gestisci qui i dati della pagina seo della lista trattamenti. Sul fondo della pagina puoi gestire i trattamenti associati alla pagina.`
        }
        sx={{
          textAlign: 'center'
        }}
      />

      {pageData ? (
        <>
        <Formik
          initialValues={{
            title: pageData?.title,
            breadcrumb: pageData?.breadcrumb,
            description: pageData?.description,
            cover: pageData?.cover
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Il titolo è obbligatorio'),
            breadcrumb: Yup.string().required('Il breadcumb è obbligatorio'),
            description: Yup.string().required('La descrizione è obbligatoria'),
            cover: Yup.string().required('La cover è obbligatoria')
          })}
          onSubmit={(values) => {
            values.metadata = pageMetadata;
            values.description = contentText;
            onUpdateSeoTreatmentsPage({ page_data: values }).then((res) => {
              toast(res?.response, {
                style: {
                  fontSize: '14px',
                  backgroundColor: res?.responseStatus ? '#00e676' : '#FF646C',
                  color: '#ffffff'
                }
              });
              if (res?.responseStatus) {
                getPage();
              }
            });
          }}
        >
          {({ errors, touched, values, setFieldValue, submitForm }) => (

            <Box>

              <UIFormControlBox label={'Breadcrumb'} error={Boolean(touched.breadcrumb && errors.breadcrumb)}>
                <UIInput
                  value={values?.breadcrumb}
                  onChange={() => setFieldValue('breadcrumb', event.target.value)}
                />
              </UIFormControlBox>

              <UIFormControlBox label={'Titolo'} error={Boolean(touched.title && errors.title)}>
                <UIInput
                  value={values?.title}
                  onChange={() => setFieldValue('title', event.target.value)}
                />
              </UIFormControlBox>

              <UIFormControlBox label={'Testo'} error={Boolean(touched.description && errors.description)}>
                <UIHtmlEditor content={values?.description} onChange={(html) => setContentText(html)} />
              </UIFormControlBox>

              <UIFormControlBox label={'Immagine'} error={Boolean(touched.cover && errors.cover)}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'} width={'55%'}>
                  <Image src={values.cover} w={'500px'} h={'100px'} objectFit={'cover'} objectPosition={'center'}
                         marginRight={'20px'} />
                  <DropzoneFormikInput
                    variant={'seo_treatments_cover'}
                    setFieldValue={setFieldValue}
                    field={'cover'}
                    label={'Carica'}
                  />
                </Box>
              </UIFormControlBox>

              <UIFormControlBox>
                <UIPageHeader
                  title={'MetaData'}
                />
                {pageMetadata?.map((metadata, index) => (
                  <Box key={index} display={'flex'} flexWrap={'wrap'} gap={'20px'} mt={10}>
                    <UIFormControlBox sx={{ display: 'block', width: 'auto', p: 0 }}>
                      <UIInputLabel label={'Name'} htmlFor={'test'} />
                      <UIInput id={'test'} label={'Name'} value={metadata?.name}
                               aria-describedby="base-name-helper-text"
                               onChange={(e) => handleMetadataUpdate(e.target.value, index, 'name')}
                               sx={{ color: 'red' }} />
                    </UIFormControlBox>
                    <UIFormControlBox sx={{ display: 'block', width: 'auto', p: 0 }}>
                      <UIInputLabel label={'Content'} htmlFor={'test1'} />
                      <UIInput id={'test1'} label={'Content'} value={metadata?.content}
                               aria-describedby="base-name-helper-text"
                               onChange={(e) => handleMetadataUpdate(e.target.value, index, 'content')}
                               sx={{ color: 'red' }} />
                    </UIFormControlBox>
                    <UIButton
                      sxCustom={{
                        bgcolor: '#FF646C',
                        color: 'white',
                        padding: '10px',
                        minWidth: 'auto'
                      }}
                      onClick={() => handleMetadataRemove(index)}
                      icon={<IconTrash stroke={1.64} size="25px" />
                      } />
                  </Box>
                ))}

                <Box mt={10}>
                  <UIButton onClick={handleMetadataAdd} label={'Aggiungi Metadata'} />
                </Box>
              </UIFormControlBox>

              <UIFormControlBox>
                <UIButton
                  type={'button'}
                  variant={'phone'}
                  label={'Aggiorna pagina'}
                  sxCustom={{
                    whiteSpace: 'nowrap',
                    height: '45px',
                    textTransform: 'none',
                    marginTop: '20px'
                  }}
                  onClick={() => submitForm()}
                />
              </UIFormControlBox>


            </Box>
          )}
        </Formik>
          <TreatmentsTable />
        </>


      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress
            sx={{
              color: '#FECE2E'
            }}
          />
        </Box>
      )}


    </Box>
  );
};


const TreatmentsTable = () => {
  const { onListPageTreatmentsAdminPaginated } = useSeo();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [addTreatmentMode, setAddTreatmentMode] = useState(false);


  const prepareValues = (treatments) =>
    treatments?.map((treatment) => {
      return {
        treatment: treatment,
        nameEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {treatment?.name}
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box
            onClick={() => {
              setSelectedTreatment(treatment);
              setEditMode(true);
            }}
          >
            <IconEdit cursor={'pointer'} />
          </Box>
        )
      };
    });

  const handleModalClose = () => {
    setEditMode(false);
    setAddTreatmentMode(false);
    setForceUpdate(true);
  };

  const filtersButtons = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px'
      }}
    >
      <Box width={'120px'}>
        <UIButton
          type={'button'}
          variant={'phone'}
          label={'Aggiungi trattamento'}
          sxCustom={{
            whiteSpace: 'nowrap',
            height: '45px',
            textTransform: 'none'
          }}
          onClick={() => setAddTreatmentMode(true)}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <ModalEditTreatment modalOpen={editMode} handleModalClose={handleModalClose} treatment={selectedTreatment} />
      <ModalAddTreatment modalOpen={addTreatmentMode} handleModalClose={handleModalClose} />
      <hr style={{ width: '100%', margin: '30px 0 10px 0', borderWidth: '2px' }} />
      <UIPageHeader
        title={'Trattamenti associati'}
        subtitle={
          'Gestisci qui i trattamenti associati alla pagina dei trattamenti.'
        }
        sx={{
          textAlign: 'center'
        }}
      />
      <UITable
        columns={[
          {
            name: 'Nome',
            value: 'nameEl',
            size: 400
          },
          {
            name: 'Azioni',
            value: 'actionsEl',
            size: 100
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        filtersButtons={filtersButtons}
        infiniteScrollEndMessage={'Non ci sono altri trattamenti da mostrare'}
        infiniteScrollFunction={onListPageTreatmentsAdminPaginated}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
    </>
  );
};


export default SeoTreatmentsPage;
