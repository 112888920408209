import React from 'react';
import { Box } from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';

export const UIHtmlEditor = ({ content, onChange }) => {

  return (
    <Box>
      <div className="p-4">
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          initialValue={content}
          init={{
            height: 500,
            plugins: [
              "image lists"
            ],
            toolbar:
              "undo redo | formatselect | bold italic removeformat | image | blocks | alignleft aligncenter alignright lineheight | backcolor forecolor | fontsize | bullist numlist",
            menu: { tools: { title: 'Tools', items: 'listprops' }},
          }}
          onEditorChange={(content) => {
            onChange(content);
          }}
        />
      </div>
    </Box>
  );
};

export default UIHtmlEditor;
