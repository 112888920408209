import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@chakra-ui/react';
import { useGeneral } from '../../hooks/General/useGeneral';
import axios from 'axios';
import { useCalendar } from '../../hooks/Calendar/useCalendar';
import { useAccounts } from '../../hooks/Accounts/useAccounts';
import { useOffices } from '../../hooks/Offices/useOffices';
import { useProtocols } from '../../hooks/Protocols/useProtocols';
import { useShop } from '../../hooks/Shop/useShop';
import { useArticles } from '../../hooks/Articles/useArticles';
import { useGlossary } from '../../hooks/Glossary/useGlossary';
import { UIButton } from './Button';
import { useSeo } from '../../hooks/Seo/useSeo';

const DropzoneFormikInput = ({
  variant,
  setFieldValue,
  field,
  setLoading,
  folder,
  label,
  type = 'image',
  extra_data = {},
  callback = () => {}
}) => {
  let { onUploadIcon } = useGeneral();
  let { onUploadStamp } = useCalendar();
  let { onUploadProfilePic, onUploadAccountMedia } = useAccounts();
  let { onUploadOfficeImage } = useOffices();
  let { onUploadProtocolCover } = useProtocols();
  let { onUploadShopProductMedia, onUploadShopBannerImage, onUploadShopOrderInvoice } = useShop();
  let { onUploadArticleCover, onUploadArticlePreview } = useArticles();
  let { onUploadGlossaryCover } = useGlossary();
  let { onUploadSurveyInvoice } = useProtocols();
  let { onUploadSeoTreatmentsCover } = useSeo();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (setLoading) setLoading(true);
      acceptedFiles?.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const imageDataURL = reader.result;
          let preSignedUrl;

          switch (variant) {
            case 'icon':
              preSignedUrl = await onUploadIcon({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'stamps':
              preSignedUrl = await onUploadStamp({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'profile_pic':
              preSignedUrl = await onUploadProfilePic({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'office_image':
              preSignedUrl = await onUploadOfficeImage({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'cover':
              preSignedUrl = await onUploadProtocolCover({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'account_media':
              preSignedUrl = await onUploadAccountMedia({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'shop_product_media':
              preSignedUrl = await onUploadShopProductMedia({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'banner':
              preSignedUrl = await onUploadShopBannerImage({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'order_invoice':
              preSignedUrl = await onUploadShopOrderInvoice({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'survey_invoice':
              preSignedUrl = await onUploadSurveyInvoice({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'article_cover':
              preSignedUrl = await onUploadArticleCover({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'article_preview':
              preSignedUrl = await onUploadArticlePreview({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'glossary_cover':
              preSignedUrl = await onUploadGlossaryCover({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
            case 'seo_treatments_cover':
              preSignedUrl = await onUploadSeoTreatmentsCover({
                filename: file.name,
                contentType: file.type,
                folder: folder,
                ...extra_data
              });
              break;
          }

          if (preSignedUrl?.presignedUrl) {
            await UploadS3(imageDataURL, preSignedUrl?.presignedUrl, file.type);

            if (setLoading) setLoading(false);
            if (setFieldValue) setFieldValue(field, preSignedUrl?.key);
            if (callback) callback(preSignedUrl?.key, preSignedUrl?.uuid);
          }
        };

        reader.readAsDataURL(file);
      });
    },
    [setLoading, variant, folder, extra_data, setFieldValue, field]
  );

  const acceptedFiles = () => {
    switch (type) {
      case 'image':
      default:
        return {
          'image/jpeg': [],
          'image/png': [],
          'image/jpg': []
        };
      case 'pdf':
        return {
          'application/pdf': []
        };
      case 'all_allowed':
        return {
          'image/jpeg': [],
          'image/png': [],
          'image/jpg': [],
          'application/pdf': []
        };
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop,
    accept: acceptedFiles()
  });

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      <UIButton variant={'copy'} label={label ?? 'Carica'} onClick={open} />
    </Box>
  );
};

const UploadS3 = async (imageFile, url, type) => {
  const bufferData = base64ToBlob(imageFile.split(',')[1], type);
  return await axios.put(url, bufferData, {
    headers: { 'Content-Type': type, 'x-amz-acl': 'public-read' }
  });
};

function base64ToBlob(base64Data, contentType) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays?.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export default DropzoneFormikInput;
