import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Error from '../views/pages/errors/error404';
import Products from './Products';
import Calendar from './Calendar';
import Treatments from './Treatments';
import Offices from './Offices';
import Users from './Users';
import Notifications from './Notifications';
import Protocols from './Protocols';
import Shop from './Shop';
import Articles from './Articles';
import Social from './Social';
import Settings from './Settings';
import Seo from './Seo';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    Articles,
    Users,
    Offices,
    Treatments,
    Protocols,
    Calendar,
    Products,
    MainRoutes,
    Shop,
    Social,
    AuthenticationRoutes,
    Notifications,
    Settings,
    Seo,
    {
      path: '*',
      element: <Error />
    }
  ]);
}
