import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { UIPageHeader } from '../../utilities/Headers';
import { UIInput, UITextarea } from '../../utilities/Input';
import { UIFormControlBox } from '../../utilities/Form';
import UIHtmlEditor from '../../utilities/Editor';
import { UIButton } from '../../utilities/Button';
import { IconCheck, IconTrash } from '@tabler/icons-react';

const ModalEditTreatment = ({ modalOpen, handleModalClose, treatment }) => {
  const [treatmentData, setTreatmentData] = useState(null);
  const [descriptionSeoText, setDescriptionSeoText] = useState('');
  const { onGetTreatmentSeoDataAdmin, onUpdateTreatmentSeoDataAdmin } = useTreatments();

  const getTreatment = () => {
    onGetTreatmentSeoDataAdmin({
      treatment_id: treatment.id
    }).then(data => {
      setTreatmentData(data);
    });
  };

  useEffect(() => {
    if (!modalOpen) return;
    getTreatment();
  }, [modalOpen]);

  const handleModalCloseInternal = () => {
    setTreatmentData(null);
    setDescriptionSeoText('');
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { slug, description_short } = values;
    onUpdateTreatmentSeoDataAdmin({
      treatment_id: treatmentData?.id,
      treatment_data: {
        slug,
        description_short,
        description_seo: descriptionSeoText
      }
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: res?.responseStatus ? '#00e676' : '#FF646C',
          color: '#ffffff'
        }
      });
      if (res?.responseStatus) {
        handleModalCloseInternal();
      }
    });
  };


  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <Formik
        initialValues={{
          slug: treatmentData?.slug || '',
          description_short: treatmentData?.description_short || '',
          description_seo: treatmentData?.description_seo || ''
        }}
        validationSchema={Yup.object().shape({
          slug: Yup.string().required('Inserisci uno slug valido'),
          description_short: Yup.string().required('Inserisci una descrizione breve')
        })}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, submitForm, handleSubmit, touched, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} id={'article-form'}>
            <UiModalInternal
              onClose={handleModalCloseInternal}
              isLoading={treatment && treatmentData === null}
              containerSx={{
                width: '80%',
                maxWidth: '800px'
              }}
            >
              <UIModalHeader sx={{ border: 'none' }}>
                <UIPageHeader
                  title={'Dati Seo Trattamento'}
                  sx={{
                    textAlign: 'center'
                  }}
                />
              </UIModalHeader>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIFormControlBox label={'Slug'} error={Boolean(touched.slug && errors.slug)}
                                  error_message={errors?.slug}>
                  <UIInput value={values.slug} onChange={(e) => setFieldValue('slug', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox
                  label={'Descrizione breve'}
                >
                  <UITextarea
                    name={'description_short'}
                    value={values?.description_short}
                    onChange={(event) => setFieldValue('description_short', event.target.value)}
                    expandable={false}
                  />
                </UIFormControlBox>

                <UIFormControlBox
                  label={'Testo Articolo SEO'}
                >
                  <UIHtmlEditor content={values?.description_seo} onChange={(html) => setDescriptionSeoText(html)} />
                </UIFormControlBox>

              </UIModalBody>
              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()}
                          icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
                <UIButton
                  type={'submit'}
                  // onClick={() => {
                  //   setDeleteMode(true);
                  // }}
                  icon={<IconTrash stroke={1.64} size="25px" />}
                  sxCustom={{
                    bgcolor: '#FF646C'
                  }}
                />
              </UIModalButtons>
            </UiModalInternal>
          </form>
        )}
      </Formik>
    </UIModal>
  );
};

export default ModalEditTreatment;
