// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import SeoTreatmentsPage from '../views/pages/seoTreatments/view';

const Seo = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'seo/treatments',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <SeoTreatmentsPage />
        </ProtectedRoute>
      )
    }
  ]
};

export default Seo;
