import {
  GET_PAGE_DATA,
  LIST_PAGE_TREATMENTS_ADMIN_PAGINATED,
  UPDATE_SEO_TREATMENTS_PAGE,
  UPLOAD_TREATMENTS_COVER
} from './graphql/queries';
import { useQuery } from '../useQuery';

/** @format */
export const useSeo = () => {
  const { getDataGQL: getPageTreatments } = useQuery(GET_PAGE_DATA);
  const { getDataGQL: listPageTreatmentsAdminPaginated } = useQuery(LIST_PAGE_TREATMENTS_ADMIN_PAGINATED);
  const { getDataGQL: uploadSeoTreatmentsCover } = useQuery(UPLOAD_TREATMENTS_COVER);
  const { getDataGQL: uploadSeoTreatmentsPage } = useQuery(UPDATE_SEO_TREATMENTS_PAGE);

  const onGetPageTreatments = async () => {
    const response = await getPageTreatments({
      variables: {
        key: 'treatments'
      }
    });
    if (response?.adminRoute?.getPageAdmin) {
      return response?.adminRoute?.getPageAdmin;
    }
    return null;
  };

  const onListPageTreatmentsAdminPaginated = async ({ filters }) => {
    const response = await listPageTreatmentsAdminPaginated({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listPageTreatmentsAdminPaginated) {
      return response?.adminRoute?.listPageTreatmentsAdminPaginated;
    }
    return null;
  }

  const onUploadSeoTreatmentsCover = async ({ filename, contentType }) => {
    const response = await uploadSeoTreatmentsCover({
      variables: {
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadSeoTreatmentsCover) {
      return response?.adminRoute?.uploadSeoTreatmentsCover;
    }
    return null;
  }

  const onUpdateSeoTreatmentsPage = async ({ page_data }) => {
    const response = await uploadSeoTreatmentsPage({
      variables: {
        page_data
      }
    });
    if (response?.adminRoute?.updateSeoTreatmentsPage) {
      return response?.adminRoute?.updateSeoTreatmentsPage;
    }
    return null;
  }


  return {
    onGetPageTreatments,
    onListPageTreatmentsAdminPaginated,
    onUploadSeoTreatmentsCover,
    onUpdateSeoTreatmentsPage
  };
};
