import { Box } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { IconBulb, IconCheck, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useShop } from '../../../hooks/Shop/useShop';

const ModalEdit = ({ modalOpen, handleModalClose, category }) => {
  let [deleteMode, setDeleteMode] = useState(false);
  let [data, setData] = useState(null);

  const { onGetShopCategory, onUpdateShopCategory, onCreateShopCategory, onDeleteShopCategory } = useShop();

  const getCategory = async () => {
    onGetShopCategory({
      category_uuid: category.uuid
    }).then((res) => {
      setDeleteMode(false);
      setData(res);
    });
  };

  useEffect(() => {
    if (category?.uuid) {
      getCategory();
    } else {
      setData(null);
    }
  }, [category]);

  const handleDeleteProduct = async () => {
    await onDeleteShopCategory({
      category_uuid: category.uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { name, type, active } = values;

    if (data?.uuid) {
      onUpdateShopCategory({
        category_uuid: data?.uuid,
        category_data: {
          name,
          type,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateShopCategory({
        category_data: {
          name,
          type,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-description"
    >
      <Box>
        <>
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina categoria</DialogTitle>
            <DialogContent>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <DialogContentText>Vuoi eliminare la categoria "{category?.name}"?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteProduct}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
          <Formik
            initialValues={{
              name: data?.name ?? '',
              type: data?.type ?? '',
              active: data?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Il nome è obbligatorio'),
              type: Yup.string().required('Il tipo è obbligatorio'),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Categoria
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={(event) => setFieldValue('name', event.target.value)}
                              label="Nome"
                              inputProps={{
                                maxLength: 50
                              }}
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.type && errors.type)}>
                            <InputLabel id="type-label">Tipologia</InputLabel>
                            <Select
                              variant={'outlined'}
                              name={'type'}
                              fullWidth={true}
                              labelId="type-label"
                              id="type-select"
                              value={values?.type}
                              label="Tipologia"
                              onChange={(e) => {
                                setFieldValue('type', e.target.value);
                              }}
                              onBlur={handleBlur}
                            >
                              <MenuItem value={'protocol'}>Protocollo</MenuItem>
                              <MenuItem value={'function'}>Funzione</MenuItem>
                              <MenuItem value={'ingredient'}>Ingredient</MenuItem>
                              <MenuItem value={'brand'}>Brand</MenuItem>
                            </Select>
                            {touched.type && errors.type && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.type}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      {category?.uuid && (
                        <Box d={'flex'} justifyContent={'center'}>
                          <Box
                            fontSize={15}
                            textAlign={'center'}
                            color={'#f44336'}
                            fontFamily={'Montserrat, sans-serif'}
                            mt={10}
                            fontWeight={700}
                            textTransform={'uppercase'}
                            bg={'rgba(200,200,200,0.2)'}
                            p={'5px '}
                            cursor={'pointer'}
                            onClick={() => setDeleteMode(true)}
                          >
                            Elimina Categoria
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={handleModalClose} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

export default ModalEdit;
