// assets
import { IconBrandWechat } from '@tabler/icons-react';
// constant
const icons = { IconBrandWechat };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const seo = {
  id: 'seo',
  type: 'group',
  permission: ['ADMIN'],
  children: [
    {
      id: 'seo_menu',
      title: 'Seo',
      type: 'collapse',
      icon: icons.IconBrandWechat,
      breadcrumbs: false,
      permission: ['ADMIN'],
      children: [
        {
          id: 'treatments',
          title: 'Trattamenti',
          type: 'item',
          url: '/seo/treatments',
          icon: icons.IconBrandWechat,
          breadcrumbs: false,
          permission: ['ADMIN']
        }
      ]
    }
  ]
};

export default seo;
