import gql from 'graphql-tag';

export const GET_PAGE_DATA = gql`
  query getPage($key: pagesKeys!) {
    adminRoute {
      getPageAdmin(key: $key){
        title
        description
        breadcrumb
        cover
        metadata {
          content
          name
        }
      }
    }
  }
`;

export const LIST_PAGE_TREATMENTS_ADMIN_PAGINATED = gql`
  query listPageTreatmentsAdminPaginated($filters: mixedFilters) {
    adminRoute {
      listPageTreatmentsAdminPaginated(filters: $filters){
        rows {
          id
          name
        }
      }
    }
  }
`;

export const UPLOAD_TREATMENTS_COVER = gql`
  query uploadSeoTreatmentsCover($filename: String!, $contentType: String!) {
    adminRoute {
      uploadSeoTreatmentsCover(filename: $filename, contentType: $contentType){
        presignedUrl
        key
      }
    }
  }
`;

export const UPDATE_SEO_TREATMENTS_PAGE = gql`
  query updateSeoTreatmentsPage($page_data: SeoTreatmentsInput!) {
    adminRoute {
      updateSeoTreatmentsPage(page_data: $page_data){
        response
        responseStatus
      }
    }
  }
`;

