import gql from 'graphql-tag';

export const LIST_TREATMENTS_ADMIN = gql`
  query listTreatmentsAdmin {
    adminRoute {
      listTreatmentsAdmin {
        id
        name
        description
        most_wanted
        sponsored
        active
        doctor_treatment {
          price
          time
          active
        }
      }
    }
  }
`;

export const LIST_TREATMENTS_ADMIN_PAGINATED = gql`
  query listTreatmentsAdminPaginated($filters: mixedFilters) {
    adminRoute {
      listTreatmentsAdminPaginated(filters: $filters) {
        rows {
          id
          name
          description
          most_wanted
          sponsored
          active
          doctor_treatment {
            price
            time
            active
          }
        }
      }
    }
  }
`;

export const LIST_TREATMENTS_DOCTOR = gql`
  query listTreatmentsDoctor {
    adminRoute {
      listTreatmentsDoctor {
        id
        name
        description
        most_wanted
        sponsored
        active
        doctor_treatment {
          price
          time
          active
        }
      }
    }
  }
`;

export const GET_TREATMENT_DOCTOR = gql`
  query getTreatmentDoctor($treatment_id: ID!) {
    adminRoute {
      getTreatmentDoctor(treatment_id: $treatment_id) {
        id
        name
        description
        most_wanted
        sponsored
        active
        doctor_treatment {
          price
          time
          active
          calendars_treatments {
            id
            calendar_id
            calendar {
              office {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_TREATMENTS_DOCTOR_PAGINATED = gql`
  query listTreatmentsDoctorPaginated($filters: mixedFilters) {
    adminRoute {
      listTreatmentsDoctorPaginated(filters: $filters) {
        rows {
          id
          name
          description
          most_wanted
          sponsored
          active
          doctor_treatment {
            price
            time
            active
          }
        }
      }
    }
  }
`;

export const LIST_TREATMENTS_TO_ADD_DOCTOR = gql`
  query listTreatmentsToAddDoctor {
    adminRoute {
      listTreatmentsToAddDoctor {
        id
        name
        description
        most_wanted
        sponsored
        active
        doctor_treatment {
          price
          time
          active
        }
      }
    }
  }
`;

export const CREATE_TREATMENT_ADMIN = gql`
  query createTreatmentAdmin($treatment_data: TreatmentAdminInput!) {
    adminRoute {
      createTreatmentAdmin(treatment_data: $treatment_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_TREATMENT_ADMIN = gql`
  query updateTreatmentAdmin($treatment_data: TreatmentAdminInput!, $treatment_id: ID!) {
    adminRoute {
      updateTreatmentAdmin(treatment_data: $treatment_data, treatment_id: $treatment_id) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_TREATMENT_ADMIN = gql`
  query updateTreatmentAdmin($treatment_id: ID!) {
    adminRoute {
      deleteTreatmentAdmin(treatment_id: $treatment_id) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_TREATMENT_DOCTOR = gql`
  query updateTreatmentDoctor($treatment_data: TreatmentDoctorInput!, $treatment_id: ID!) {
    adminRoute {
      updateTreatmentDoctor(treatment_data: $treatment_data, treatment_id: $treatment_id) {
        response
        responseStatus
      }
    }
  }
`;

export const HIDE_TREATMENT_DOCTOR = gql`
  query hideTreatmentDoctor($treatment_id: ID!) {
    adminRoute {
      hideTreatmentDoctor(treatment_id: $treatment_id) {
        response
        responseStatus
      }
    }
  }
`;

export const ASSIGN_TREATMENTS_TO_CALENDAR = gql`
  query assignTreatmentsToCalendar($treatments: [ID]!, $calendar_id: ID!) {
    adminRoute {
      assignTreatmentsToCalendar(treatments: $treatments, calendar_id: $calendar_id) {
        response
        responseStatus
      }
    }
  }
`;


export const GET_TREATMENT_SEO_DATA_ADMIN = gql`
  query getTreatmentSeoDataAdmin($treatment_id: ID!) {
    adminRoute {
      getTreatmentSeoDataAdmin(treatment_id: $treatment_id){
        id
        slug
        description_seo
        description_short
      }
    }
  }
`;

export const LIST_TREATMENTS_SEO_ADMIN = gql`
  query listTreatmentsSeoAdmin{
    adminRoute {
      listTreatmentsSeoAdmin{
        id
        name
      }
    }
  }
`;


export const UPDATE_TREATMENT_SEO_DATA_ADMIN = gql`
  query updateTreatmentSeoDataAdmin($treatment_id: ID!, $treatment_data: SeoTreatmentInput!) {
    adminRoute {
      updateTreatmentSeoDataAdmin(treatment_id: $treatment_id, treatment_data: $treatment_data){
        responseStatus
        response
      }
    }
  }
`;


export const ADD_TREATMENT_SEO_DATA_ADMIN = gql`
  query addTreatmentSeoDataAdmin($treatment_id: ID!) {
    adminRoute {
      addTreatmentSeoDataAdmin(treatment_id: $treatment_id){
        responseStatus
        response
      }
    }
  }
`;
